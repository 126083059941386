<template>
    <div class="template-page">
        <basic-page :fulldata="fulldata" :pagedata="this.pageData" :bannerimg="this.bannerimg">
        </basic-page>
    </div>
</template>

<script>
//import { fetchSingleNode } from '../libs/drupalClient'
import BasicPage from './BasicPage.vue'

export default {
  components: { BasicPage },
    name: 'default',
    data: () => {
        return {
            currentPageId: "667a0e98-45f9-40e4-9f2b-3256918a8a81",
            pageData: {},
            fulldata: {},
            bannerimg: null
        }
    },
    methods: {
    },
    computed: {
    },
    mounted() {
      /*fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top','field_page_image', 'field_attachments'] })
          .then(res => {
              this.pageData = res[0].attributes
            this.fulldata = res[0]
          })*/
    }
}
</script>

<style lang="scss" scoped>
</style>